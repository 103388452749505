import { getAll } from "../business-delegate";
import { ITodos } from "./todos.interface";


class TodoService implements ITodos {
    constructor() {

    }
    /**
     * 
     * @param url 
     * @returns 
     */
    async getlistTodos(url: string): Promise<{ data: any[] }> {
        const result = await getAll(url);

        return result;
    }

}

export default TodoService;