import { applyMiddleware, createStore } from '@reduxjs/toolkit';

import { composeWithDevTools } from 'redux-devtools-extension';
import middleware from './middleware';
import reducer from './reducer';

export default createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
)

// import { configureStore } from '@reduxjs/toolkit';
// import middleware from './middleware';
// import reducer from './reducer';

// export default configureStore({
//   reducer: reducer,
//   middleware(getDefaultMiddleware) {
//     return getDefaultMiddleware().concat(...middleware)
//   },
// })