import axios from "axios";
import { generateUrl, stringifyParams } from "./helper";

/** header config */
const header_config = {
    headers: { Authorization: `Bearer` }
};

/**
 * get all datas
 * @param url 
 * @returns 
 */
export const getAll = async (url: string): Promise<any> => {
    const _url = generateUrl(url);
    try {
        const result = await axios.get(_url, header_config);
        return result;
    }
    catch (error: any) {
        return new Error(JSON.parse(error));
    }
}

/**
 * get data by params
 * @param url 
 * @param params 
 * @returns 
 */
export const getByParams = async (url: string, params: any): Promise<any> => {
    const _url = stringifyParams(url, params);
    try {
        const result = await axios.get(_url, header_config);
        return result;
    }
    catch (error: any) {
        return new Error(JSON.parse(error));
    }
}

/**
 * post dada inside body
 * @param url
 * @param body
 * @returns
 */
export const post = async (url: string, body: any): Promise<any> => {
    const _url = generateUrl(url);
    try {
        const result = await axios.post(_url, body,);
        return result;
    }
    catch (error: any) {
        return new Error(JSON.parse(error));
    }
};

/**
 * update data inside body
 * @param url
 * @param body
 * @returns
 */
export const update = async (url: string, body: any): Promise<any> => {
    const _url = generateUrl(url);
    try {
        const result = await axios.put(_url, body);
        return result;
    }
    catch (error: any) {
        return new Error(JSON.parse(error));
    }
};

/**
 * remove by data in params
 * @param url
 * @param params
 * @returns
 */
export const remove = async (url: string): Promise<any> => {
    const _url = generateUrl(url);
    try {
        const result = await axios.delete(_url);
        return result;
    }
    catch (error: any) {
        return new Error(JSON.parse(error));
    }
};