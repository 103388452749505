import { BASE_URL } from "../../../../application/config/env";

/** base url*/
const base_url = BASE_URL;

/**
 * 
 * @param url 
 * @returns 
 */
 const generateUrl = (url: string): string => {

    return `${base_url}${url}`;
}
                  
/**
 * 
 * @param url 
 * @param oData 
 * @returns 
 */
const stringifyParams = (url: string, oData: Object) : string => {
    const sParams = Object.entries(oData).reduce((total, item, idx) => {
        const symbol = idx === 0 ? "?" : "&";
        return total + symbol + item[0] + "=" + item[1];
    }, "");

    return `${generateUrl(url)}${sParams}`;
};

export {
    generateUrl,
    stringifyParams,
};

