import { TodoService } from "../../../../infrastructure/service/api";
import { IAction } from "../../../model/dto/action/action.dto";
import { loadTodosFailure, loadTodosSuccess, LOAD_TODOS, UPDATE_TODOS } from "../../actions/home/home.action";
import { pageLoading } from "../../actions/ui/ui.action";

const loadTodosFlow = ({ dispatch }: { dispatch: Function }) => (next: Function) => async (action: IAction) => {
    next(action);
    if (action.type === LOAD_TODOS) {
        dispatch(pageLoading(true));
        try {
            const todoService : {data : any[]}= await new TodoService().getlistTodos("/todos");
            dispatch(loadTodosSuccess(todoService.data))
            dispatch(pageLoading(false));                       
        }
        catch (error) {
            dispatch(loadTodosFailure(error));                                               
            dispatch(pageLoading(false));
        }
    }
}

const updateTodosFlow = ({ dispatch, getState }: { dispatch: Function, getState: Function ,}) => (next: Function) => async (action: IAction) => {
    next(action);
    if (action.type === UPDATE_TODOS) {
        const newItem = action.payload;
        const currentTodos = getState().homeReducer.todos;
        const newTodos = currentTodos.map((todo: any) => {
            if (todo.id === newItem.id) {
                todo.completed = !todo.completed;
            }
            return todo;
        })
        dispatch(loadTodosSuccess(newTodos));
    }
}

export default [
    loadTodosFlow,
    updateTodosFlow
]