import { IAction } from "../../../model/dto/action/action.dto";
import { LOAD_TODOS_FAILURE, LOAD_TODOS_SUCCESS } from "../../actions/home/home.action";

export const todosInitial = {
    todos: [],
    error: false,
}
const homeReducer = (initial = todosInitial, action: IAction) => {
    switch (action.type) {
        case LOAD_TODOS_SUCCESS:
            return { ...initial, error: false, todos: action.payload };
        case LOAD_TODOS_FAILURE:
            return { ...initial, error: true, todos: action.payload }
        default:
            return initial;

    }
}

export default homeReducer;