import { IAction } from "../../../model/dto/action/action.dto";

export const LOAD_TODOS = '[todos] load';
export const LOAD_TODOS_SUCCESS = '[todos] load success';
export const LOAD_TODOS_FAILURE = '[todos] load failure';
export const UPDATE_TODOS = "[todos] update"


export const loadTodos = {
    type: LOAD_TODOS
};

export const updateTodos = (item :any) : IAction =>({
    type: UPDATE_TODOS,
    payload: item
})

export const loadTodosSuccess = (todos: any): IAction => ({
    type: LOAD_TODOS_SUCCESS,
    payload: todos
});

export const loadTodosFailure = (error: any): IAction => ({
    type: LOAD_TODOS_FAILURE,
    payload: error
});