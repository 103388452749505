import { IAction } from "../../../model/dto/action/action.dto";
import { PAGE_LOADING } from "../../actions/ui/ui.action";


const initialLoading = {
    isLoading: false
}
 const uiReducer = (initial = initialLoading, action: IAction) => {
     switch (action.type) {
         case PAGE_LOADING:
            return { ...initial, isLoading: action.payload };
        default:
            return initial;
    }
}

export default uiReducer;