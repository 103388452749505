import React, { lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import '../../application/assets/css/global.css';


export class View extends React.Component {
    LoginPage: any = lazy(() => import('./public/login/login.container'));
    HomePage: any = lazy(() => import('./protected/home/home.screen'));
    RequestOtpPage: any = lazy(() => import('./protected/requestOtp/requestOtp.screen'));
    SendOtpCodePage: any = lazy(() => import('./protected/sendOtpCode/sendOtpCode.screen'));
    CapturePhotoPage: any = lazy(() => import('./protected/capturePhoto/capturePhoto.screen'));
    AcknowledgementPage: any = lazy(() => import('./protected/acknowledgement/acknowledgement.screen'));

    render(): React.ReactNode {
        return (
            <BrowserRouter>
                <Suspense fallback={<div className="loading">Loading ...</div>}>
                    <Routes>
                        <Route path="/login" element={<this.LoginPage />} />
                        <Route path="" element={<Navigate replace to="/login" />} />
                        <Route path="/home" element={<this.HomePage />} />
                        <Route path="/requestOtp" element={<this.RequestOtpPage />} />
                        <Route path="/requestOtp/sendOtpCode" element={<this.SendOtpCodePage />} />
                        <Route path="/mobile/partnership/token=:token" element={<this.CapturePhotoPage />} />
                        <Route path="/acknowledgement" element={<this.AcknowledgementPage />} />
                        <Route path="*" element={<this.LoginPage />} />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        )
    }
}